// Components/ui
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { GetMessagesData } from '../../../../../../../../redux/features/messagesSlice/types';
import { ClientConversation } from '../../../../../../../../redux/features/chatsSlice/types';
// Redux
import { setOpenChatStatus } from '../../../../../../../../redux/features/chatsSlice/chatsSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { selectChatById } from '../../../../../../../../redux/features/chatsSlice/selectors';
import {
  fetchMessages,
  setFetchMessagesLoadingState,
} from '../../../../../../../../redux/features/messagesSlice/messagesSlice';
// Functions
import {
  getAllMessagesDb,
  getFetchMessagesData,
  getMessagesRelationId,
} from '../../../../../../../../redux/features/messagesSlice/functions';
// Db
import { conversationsDbWorker } from '../../../../../../../../db/conversationsDb';
import { chatsDbWorker } from '../../../../../../../../db/chatDb';

interface AvatarChatItemProps {
  chatId: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  justifyItems: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '0.5rem',
  cursor: 'pointer',
  transition: 'background-color 0.15s',
  backgroundColor: 'transparent',
  width: '75px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 30,
  height: 30,
});

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%',
});

const AvatarChatItem = ({ chatId }: AvatarChatItemProps) => {
  const dispatch = useAppDispatch();

  const chat = useSelector(selectChatById(chatId));

  async function openChatItemWindow(chat: ClientConversation) {
    if (!chat.open) {
      const id = getMessagesRelationId(chat);
      const fetchMessagesData: GetMessagesData = getFetchMessagesData(
        chat.type,
        chat.id,
        id
      );

      getAllMessagesDb(chat.type, id);

      dispatch(setOpenChatStatus({ id: chat.id, status: true }));
      dispatch(setFetchMessagesLoadingState(id));
      dispatch(fetchMessages(fetchMessagesData));
    }
  }
  return (
    <StyledBox onClick={() => openChatItemWindow(chat)}>
      <StyledAvatar src={chat.chatImage} />
      <StyledTypography color="text.primary" variant="caption">
        {chat.type === ChatType.EXTERNAL ? chat.contactInfo.name : chat.chatName}
      </StyledTypography>
    </StyledBox>
  );
};

export default AvatarChatItem;
