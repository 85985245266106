import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setRecordAudioStream } from '../audioRecordSlice/audioRecordSlice';
// Types
import { ChatRecordAudioMode, ChatsState, ClientConversation } from './types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../rootReducer';
import { MediaFile } from '../messagesSlice/types';
import { AppThunk } from '../../appThunk';
// Utils
import chatsSliceService from './service';
import initRequestData from '../../functions/initRequestData';
import ObjectID from 'bson-objectid';
import { IConversation } from '@trii/types/dist/Conversations';

// Acciones como constantes para mayor seguridad y mantenimiento
export const CHAT_ACTIONS = {
  FETCH: 'chats/fetchChats',
  DELETE_WINDOW: 'chats/deleteFloatingWindow',
};

// Estado inicial del slice
const initialState: ChatsState = {
  chats: {},
};

// Thunk para obtener los chats
export const fetchChats = createAsyncThunk(
  CHAT_ACTIONS.FETCH,
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;
      const { user } = state.User;

      return await chatsSliceService.fetchFloatingWindowChats(
        URL_CONVERSATIONS,
        jwtToken,
        user.uid
      );
    } catch (error) {
      return rejectWithValue('Error fetching chats');
    }
  }
);

// Thunk para eliminar la ventana flotante de chats
export const deleteFloatingWindow = createAsyncThunk<
  void, // tipo de retorno si el proceso es exitoso
  { chatId: string; conversationId: string }, // argumentos que el thunk recibe
  { rejectValue: string } // configuración del thunk para manejar errores
>(
  'chats/deleteFloatingWindow',
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;
      const { user } = state.User;

      await chatsSliceService.deleteFloatingWindow(
        URL_CONVERSATIONS,
        jwtToken,
        user.uid,
        data
      );
    } catch (error) {
      return rejectWithValue('Error deleting floating window');
    }
  }
);

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setOpenChatStatus: (
      state,
      action: PayloadAction<{ id: string; status: boolean }>
    ) => {
      state.chats[action.payload.id].open = action.payload.status;
    },
    addNewChat: (state, action: PayloadAction<ClientConversation>) => {
      // const newClientConversation: ClientConversation = {
      //   ...action.payload,
      //   open: true,
      //   inputValue: '',
      //   recordAudioMode: false,
      //   files: [],
      //   documents: [],
      //   filesDoneLoading: false,
      // };
      const newClientConversation: ClientConversation = action.payload;

      state.chats[action.payload.id] = newClientConversation;
    },
    removeChatById: (state, action: PayloadAction<string>) => {
      delete state.chats[action.payload];
    },
    updateChatInput: (
      state,
      action: PayloadAction<{ id: string; value: string }>
    ) => {
      const { id, value } = action.payload;
      if (state.chats[id]) {
        state.chats[id].inputValue = value;
      }
    },
    setRecordAudioMode: (state, action: PayloadAction<ChatRecordAudioMode>) => {
      const { chatId, state: recordAudioMode } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].recordAudioMode = recordAudioMode;
      }
    },
    setChatFiles: (
      state,
      action: PayloadAction<{ chatId: string; files: MediaFile[] }>
    ) => {
      const { chatId, files } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].files.push(...files);
      }
    },
    setChatDocuments: (
      state,
      action: PayloadAction<{ chatId: string; files: MediaFile[] }>
    ) => {
      const { chatId, files } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].documents.push(...files);
      }
    },
    deleteChatFile: (
      state,
      action: PayloadAction<{ chatId: string; fileId: string }>
    ) => {
      const { chatId, fileId } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].files = state.chats[chatId].files.filter(
          (file) => file.id !== fileId
        );
      }
    },
    deleteChatFiles: (state, action: PayloadAction<string>) => {
      state.chats[action.payload].files = [];
    },
    deleteChatDocument: (
      state,
      action: PayloadAction<{ chatId: string; fileId: string }>
    ) => {
      const { chatId, fileId } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].documents = state.chats[chatId].documents.filter(
          (file) => file.id !== fileId
        );
      }
    },
    deleteChatDocuments: (state, action: PayloadAction<string>) => {
      state.chats[action.payload].documents = [];
    },
    setFilesDoneLoading: (
      state,
      action: PayloadAction<{ chatId: string; doneLoading: boolean }>
    ) => {
      const { chatId, doneLoading } = action.payload;
      if (state.chats[chatId]) {
        state.chats[chatId].filesDoneLoading = doneLoading;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChats.fulfilled, (state, action) => {
      const clientChatsById: Record<string, ClientConversation> =
        action.payload.reduce((acc, chat) => {
          acc[chat.id] = {
            ...chat,
            open: false,
            inputValue: '',
            recordAudioMode: false,
            files: [],
            documents: [],
          };
          return acc;
        }, {});
      state.chats = clientChatsById;
    });
  },
});

// Funciones para manejar el inicio y fin del modo de grabación de audio
export const startAudioRecordMode =
  (chatId: string): AppThunk =>
  (dispatch, getState) => {
    const { chats } = getState().Chats;
    const anyChatWithRecordAudioMode = Object.values(chats).find(
      (chat) => chat.recordAudioMode
    );

    if (anyChatWithRecordAudioMode) {
      dispatch(endRecordAudioMode(anyChatWithRecordAudioMode.id));
    }

    const chat = chats[chatId];
    if (!chat) return;

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      dispatch(setRecordAudioStream(stream));
      dispatch(setRecordAudioMode({ chatId, state: true }));
    });
  };

export const endRecordAudioMode =
  (chatId: string): AppThunk =>
  (dispatch, getState) => {
    const { chats } = getState().Chats;
    const { recordAudioStream } = getState().AudioRecord;
    const chat = chats[chatId];

    if (chat) {
      dispatch(setRecordAudioMode({ chatId, state: false }));
      recordAudioStream?.getTracks().forEach((track) => track.stop());
    }
  };

// Función de ayuda para mapear archivos con IDs únicos
const processFiles =
  (inputElement: HTMLInputElement, chatId: string, isDocument: boolean): AppThunk =>
  (dispatch) => {
    const newFiles = Array.from(inputElement.files || []);
    if (newFiles.length === 0) return;

    const newFilesWithId = chatsSliceService.mapFilesWithId(newFiles, isDocument);

    if (isDocument) {
      dispatch(setChatDocuments({ chatId, files: newFilesWithId }));
    } else {
      dispatch(setChatFiles({ chatId, files: newFilesWithId }));
    }
  };

export const handleFileUpload = (
  inputElement: HTMLInputElement,
  chatId: string
): AppThunk => processFiles(inputElement, chatId, false);

export const handleDocumentUpload = (
  inputElement: HTMLInputElement,
  chatId: string
): AppThunk => processFiles(inputElement, chatId, true);

// Exportar acciones y el reducer
export const {
  setOpenChatStatus,
  removeChatById,
  updateChatInput,
  setRecordAudioMode,
  setChatFiles,
  deleteChatFile,
  deleteChatFiles,
  setChatDocuments,
  deleteChatDocument,
  deleteChatDocuments,
  setFilesDoneLoading,
  addNewChat,
} = chatsSlice.actions;

export default chatsSlice.reducer;
