import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// Utils
import messagesService from './service';
import initRequestData from '../../functions/initRequestData';
// Types
import {
  GetMessagesData,
  MessagesSliceState,
  SendMessageThunkData,
  UploadMedia,
  UploadURLParams,
} from './types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IMessage } from '@trii/types/dist/Common/Messages';
// Db
import { conversationsDbWorker } from '../../../db/conversationsDb';
import { chatsDbWorker } from '../../../db/chatDb';
import {
  convertDataUrlToBlob,
  groupMessagesByDate,
  sortGroupMessagesByDate,
} from './functions';
import { ChatType } from '@trii/types/dist/Conversations';

const initialState: MessagesSliceState = {
  messages: [],
  status: {
    fetchMessages: [],
  },
};

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async (getMessagesData: GetMessagesData, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { contactId, shardKey } = getMessagesData;
    const id = contactId || shardKey;

    const response = await messagesService.fetchMessages(
      jwtToken,
      URL_MESSAGES,
      getMessagesData
    );

    if (contactId)
      await conversationsDbWorker.postMessage({
        action: 'updateMessages',
        data: { messages: response, contactId },
      });

    if (shardKey) {
      await chatsDbWorker.postMessage({
        action: 'updateMessages',
        data: { messages: response, shardKey },
      });
    }

    return { response, id };
  }
);

export const sendMessageThunk = createAsyncThunk(
  'messages/sendMessageThunk',
  async (data: SendMessageThunkData, { dispatch }) => {
    const { jwtToken, URL_MESSAGES, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { message, chatType } = data;

    if (message && 'audio' in message && message.audio?.url) {
      const blob = convertDataUrlToBlob(message.audio.url);
      const formData = new FormData();
      const urlParams: UploadURLParams = {
        folderType: 'audios',
        module: chatType === ChatType.EXTERNAL ? 'messages' : 'chat',
      };

      formData.append('file', blob);

      const response = await messagesService.uploadAudio(
        jwtToken,
        URL_MEDIA,
        formData,
        '',
        '',
        urlParams
      );

      message.audio.url = response.url;
    }

    const response = await messagesService.sendMessages(
      jwtToken,
      URL_MESSAGES,
      data.message
    );

    return { message: response, chatType };
  }
);

export const uploadMedia = createAsyncThunk(
  'conversation/uploadMedia',
  async (data: UploadMedia, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { name, file, id, URLParams } = data;
    const response = await messagesService.uploadMedia(
      jwtToken,
      URL_MEDIA,
      file,
      name,
      id,
      URLParams
    );

    return response;
  }
);

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setFetchMessagesLoadingState: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const chatStatus = state.status.fetchMessages.find(
        (status) => status.id === id
      );

      if (chatStatus) {
        chatStatus.state = 'loading';
      } else {
        state.status.fetchMessages.push({ id, state: 'loading' });
      }
    },
    setMessagesByEntity: (state, action: PayloadAction<IMessage[]>) => {
      const messages = action.payload;
      const id = messages[0]?.shardKey?.includes('_chat_')
        ? messages[0].shardKey
        : messages[0].contactId;

      const messagesGroupByDate = groupMessagesByDate(messages);
      const existingMessageGroup = state.messages.find(
        (messages) => messages.id === id
      );

      if (existingMessageGroup) {
        existingMessageGroup.messages = messagesGroupByDate;
      } else {
        state.messages.push({ id, messages: messagesGroupByDate });
      }
    },
    addMessage: (state, action: PayloadAction<IMessage>) => {
      // const newMessage = action.payload;
      // const id = newMessage.shardKey?.includes('_chat_')
      //   ? newMessage.shardKey
      //   : newMessage.contactId;
      // const existingMessageGroup = state.messages.find(
      //   (messages) => messages.id === id
      // );
      // if (existingMessageGroup) {
      //   const newMessageDate = newMessage.timestamp.toLocaleDateString('en-GB', {
      //     day: '2-digit',
      //     month: '2-digit',
      //     year: 'numeric',
      //   });
      //   const existingMessageGroupByDate = existingMessageGroup.messages.find(
      //     (message) => message.date === newMessageDate
      //   );
      //   if (existingMessageGroupByDate) {
      //     existingMessageGroupByDate.messages.push(newMessage);
      //   }
      // }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { id, response } = action.payload;
        const existingMessages = state.messages.find(
          (messages) => messages.id === id
        );
        const chatStatus = state.status.fetchMessages.find(
          (status) => status.id === id
        );

        const messagesGroupByDate = groupMessagesByDate(response);
        const messagesGroupSortedByDate =
          sortGroupMessagesByDate(messagesGroupByDate);

        if (chatStatus) {
          chatStatus.state = 'idle';
        } else {
          state.status.fetchMessages.push({ id, state: 'idle' });
        }

        if (existingMessages) {
          existingMessages.messages = messagesGroupSortedByDate;
        } else {
          state.messages.push({
            id,
            messages: messagesGroupSortedByDate,
          });
        }
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        const { contactId, shardKey } = action.meta.arg;
        const id = contactId || shardKey;

        const chatStatus = state.status.fetchMessages.find(
          (status) => status.id === id
        );

        if (chatStatus) {
          chatStatus.state = 'idle';
        }
      })
      .addCase(
        sendMessageThunk.fulfilled,
        (state, action: PayloadAction<SendMessageThunkData>) => {
          const { message, chatType } = action.payload;

          if (chatType === ChatType.EXTERNAL) {
            conversationsDbWorker.postMessage({
              action: 'setMessage',
              data: message,
            });
          } else {
            chatsDbWorker.postMessage({
              action: 'setMessage',
              data: message,
            });
          }
        }
      );
  },
});

export const { setFetchMessagesLoadingState, setMessagesByEntity, addMessage } =
  messagesSlice.actions;

export default messagesSlice.reducer;
